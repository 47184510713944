import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import AnuntItem from "../InformatiiPublice/ComponenteInformatiiPublice/ComponentaLista";
import ComponentaHeroSection from "../InformatiiPublice/ComponenteInformatiiPublice/ComponentaHeroSection";
import DepartLine from "../DepartLine";
import "../InformatiiPublice/Anunturi.css";

const data = {
  "Anunțuri": [
    {
      title: "Anunț Mandatari Financiari",
      description: "Anunț important pentru mandatari financiari",
      date: "16.04.2024",
      pdfs: ["Alegeri2024/anunt_mandatari_financiari.pdf"],
    },
    {
      title: "Anunț Alegeri",
      description: "Detalii despre alegerile din 2024",
      date: "24.04.2024",
      pdfs: ["Alegeri2024/anunt.pdf"],
    },
    {
      title: "Comunicare Membrii BESV",
      pdfs: ["Alegeri2024/comunicat_membrii_BESV.pdf"],
    },
  ],
  "Hotărâri": [
    {
      title: "Hotărâre",
      pdfs: ["Alegeri2024/hotarareanr.1.pdf"],
    },
    {
      title: "Hotărâre",
      pdfs: ["Alegeri2024/hotarareanr.2.pdf"],
    },
    {
      title: "Hotărâre",
      pdfs: ["Alegeri2024/hotarareanr.3.pdf"],
    },
    {
      title: "Hotărâre",
      pdfs: ["Alegeri2024/hotarareanr.4.pdf"],
    },
    {
      title: "Hotărâre",
      pdfs: ["Alegeri2024/hotarareanr.5.pdf"],
    },
    {
      title: "Hotărâre",
      pdfs: ["Alegeri2024/hotarareanr.7.pdf"],
    },
    {
      title: "Hotărâre",
      pdfs: ["Alegeri2024/hotarareanr.8.pdf"],
    },
    {
      title: "Hotărâre",
      pdfs: ["Alegeri2024/hotarareanr.9.pdf"],
    },
    {
      title: "Hotărâre",
      pdfs: ["Alegeri2024/hotarareanr.10.pdf"],
    },
    {
      title: "Hotărâre",
      pdfs: ["Alegeri2024/hotarareanr.11.pdf"],
    },
    {
      title: "Hotărâre",
      pdfs: ["Alegeri2024/hotarareanr.12.pdf"],
    },
    {
      title: "Hotărâre",
      pdfs: ["Alegeri2024/hotarareanr.13.pdf"],
    },
    {
      title: "Hotărâre",
      pdfs: ["Alegeri2024/hotarareanr.14.pdf"],
    },
    {
      title: "Hotărâre",
      pdfs: ["Alegeri2024/hotarareanr.15.pdf"],
    },
    {
      title: "Hotărâre",
      pdfs: ["Alegeri2024/hotarareanr.16.pdf"],
    },
  ],
  "Candidați": [
    {
      title: "Lista Consilieri PSD",
      description: "Descriere Lista Consilieri PSD",
      date: "25.04.2024",
      pdfs: ["Alegeri2024/LISTACONSILIERIPSD.pdf"],
    },
    {
      title: "Propunere Candidatură PSD",
      description: "Propunere Candidatură PSD",
      date: "25.04.2024",
      pdfs: ["Alegeri2024/PROPUNERECANDIDATURAPSD.pdf"],
    },
    {
      title: "Lista Consilieri PNL",
      description: "Descriere Lista Consilieri PNL",
      date: "29.04.2024",
      pdfs: ["Alegeri2024/LISTACONSILIERIPNL.pdf"],
    },
    {
      title: "Propunere Candidatură PNL",
      description: "Propunere Candidatură PNL",
      date: "29.04.2024",
      pdfs: ["Alegeri2024/PROPUNERECANDIDATURAPOPAIONUT.pdf"],
    },
    {
      title: "Lista Consilieri AUR",
      description: "Descriere Lista Consilieri AUR",
      date: "30.04.2024",
      pdfs: ["Alegeri2024/LISTACONSILIERIAUR.pdf"],
    },
    {
      title: "Propunere Candidatură AUR",
      description: "Propunere Candidatură AUR",
      date: "30.04.2024",
      pdfs: ["Alegeri2024/PROPUNERECANDIDATURAAUR.pdf"],
    },
  ],
  "Procese Verbale": [
    {
      title: "Proces Verbal",
      pdfs: ["Alegeri2024/PV_afisare_presedinti_sectii.pdf"],
    },
    {
      title: "Proces Verbal",
      pdfs: ["Alegeri2024/procesverbal1.pdf"],
    },
    {
      title: "Proces Verbal",
      pdfs: ["Alegeri2024/proves_verbal_ordin1.pdf"],
    },
    {
      title: "Proces Verbal",
      pdfs: ["Alegeri2024/provesverbal2.pdf"],
    },
    {
      title: "Proces Verbal",
      pdfs: ["Alegeri2024/proces_verbal_aur.pdf"],
    }
  ],
};

const Votare = () => {
  const [expandedCategory, setExpandedCategory] = useState("");

  const toggleCategory = (category) => {
    setExpandedCategory(expandedCategory === category ? "" : category);
  };

  return (
    <div className="container-aux">
      <ComponentaHeroSection />
      <DepartLine text="Alegeri 2024" />
      <div className="container-2">
        {Object.keys(data).map((category) => (
          <div key={category} style={{ paddingBottom: "20px" }}>
            <Row onClick={() => toggleCategory(category)} style={{ cursor: "pointer", marginBottom: "10px" }}>
              <Col>
                <h4>{category}</h4>
              </Col>
            </Row>
            {expandedCategory === category && (
              <Row>
                <Col>
                  {data[category].map((anunt, index) => (
                    <AnuntItem
                      key={index}
                      title={anunt.title}
                      description={anunt.description}
                      date={anunt.date}
                      pdfs={anunt.pdfs}
                    />
                  ))}
                </Col>
              </Row>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Votare;
