import React from 'react';
import './Despre.css';
import BarChart from './BarChart.js';

const Despre = () => {
  return (
    <div className="dragodana-container">
      <p className='p'>Dragodana este o comună în județul Dâmbovița, Muntenia, România, formată din satele:</p>
      <ul className="sate">
        <li>Boboci</li>
        <li>Burduca</li>
        <li>Cuparu</li>
        <li>Dragodana (reședința)</li>
        <li>Pădureni</li>
        <li>Picior de Munte</li>
        <li>Străoști</li>
      </ul>
      <p className='p'>Comuna Dragodana este traversată de șoseaua DN72, ce leagă orașul Găești de Municipiul resedință de județ, Târgoviște.</p>
      <p className='p'>Conform recensământului efectuat în 2011, populația comunei Dragodana se ridică la 6.775 de locuitori. Majoritatea locuitorilor sunt români (97,12%). Pentru 2,57% din populație, apartenența etnică nu este cunoscută. Din punct de vedere confesional, majoritatea locuitorilor sunt ortodocși (93,76%), cu o minoritate de creștini după evanghelie (1,31%). Pentru 2,58% din populație, nu este cunoscută apartenența confesională.</p>
      <BarChart />
      <p className='p'>Comuna Dragodana este administrată de un primar și un consiliu local compus din 15 consilieri.</p>
      <p className='p'>Primarul, Gheorghe Sandu, de la Partidul Social Democrat, este în funcție din 2016. </p>
      <p className='p'>Începând cu alegerile locale din 2020, consiliul local are următoarea componență pe partide politice:</p>
      <table>
        <div className='paddergraph'>
        <thead>
            <tr>
                <th></th>
                <th>Partid</th>
                <th>Consilieri</th>
                <th>Componenta Consiliului</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="color-box red"></td>
                <td>Partidul Social Democrat</td>
                <td>7</td>
                <td>
                    <div class="color-box red"></div>
                    <div class="color-box red"></div>
                    <div class="color-box red"></div>
                    <div class="color-box red"></div>
                    <div class="color-box red"></div>
                    <div class="color-box red"></div>
                    <div class="color-box red"></div>
                </td>
            </tr>
            <tr>
                <td class="color-box blue"></td>
                <td>Partidul Pro Romania</td>
                <td>4</td>
                <td>
                    <div class="color-box blue"></div>
                    <div class="color-box blue"></div>
                    <div class="color-box blue"></div>
                    <div class="color-box blue"></div>
                </td>
            </tr>
            <tr>
                <td class="color-box yellow"></td>
                <td>Partidul National Liberal</td>
                <td>4</td>
                <td>
                    <div class="color-box yellow"></div>
                    <div class="color-box yellow"></div>
                    <div class="color-box yellow"></div>
                    <div class="color-box yellow"></div>
                </td>
            </tr>
        </tbody>
        </div>
      </table>
    </div>
  );
};

export default Despre;

