import React, { useState } from 'react';
import './ComponentaLista.css';

function AnuntItem(props) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className={`anunt-item ${isOpen ? "anunt-item-expanded" : ""}`} onClick={handleClick}>
      <div className="anunt-summary">
        <h3>{props.title}</h3>
      </div>
      {isOpen && (
        <div className="anunt-details">
          <p className="anunt-item-title">{props.description}</p>
          <p className="anunt-item-description">{props.text}</p>
          <p className="anunt-item-date">{props.date}</p>
          {props.images &&
            props.images.map((image, index) => (
              <img
                key={index}
                src={process.env.PUBLIC_URL + "/" + image}
                alt=""
                className="anunt-image"
                onClick={() => openInNewTab("/images/" + image)}
              />
            ))}
          {props.pdfs &&
            props.pdfs.map((pdf, index) => (
              <button
                key={index}
                className="anunt-pdf"
                onClick={() => openInNewTab("/images/" + pdf)}
              >
                Deschide PDF
              </button>
            ))}
        </div>
      )}
    </div>
  );
}

export default AnuntItem;
