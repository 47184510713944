import React from 'react';
import Banner from './Banner';
import Card from './Card';
import DepartLine from './DepartLine';
import { Container, Row, Col } from 'react-bootstrap';
import './Home.css'
import Despre from './Despre';
import imaginePrimar from '../images/primar.png';
import imagineGhiseulRo from '../images/ghiseulro.png';
import imagineComunitate from '../images/comunitateComuna.jpg';
import imagineMaps from '../images/maps.png';

const Home = () => {
  return (
    <>
      <Banner />
      <DepartLine text="Primaria Dragodana"/>
      <div className="full-width-container">
      <Container style={{ paddingTop: '2.6vh', paddingBottom: '3.4vh' }}>
        <Row>
          <Col md={4}>
            <Card
              imageSrc={imaginePrimar}
              title="Primarul Gheorghe Sandu"
              buttonText="Vezi pagina Primarului!"
              link="/administratie/primar"
            />
          </Col>
          <Col md={4}>
            <Card
              imageSrc={imagineComunitate}
              title="Comunitatea comunei"
              buttonText="Fii la curent cu ultimele noutati!"
              link="https://www.facebook.com/groups/dragodana"
            />
          </Col>
          <Col md={4}>
            <Card
              imageSrc={imagineMaps}
              title="Unde ne gasesti?"
              buttonText="Viziteaza-ne!"
              link="https://www.google.com/maps?q=primaria+dragodana&um=1&ie=UTF-8&sa=X&ved=2ahUKEwirnIya9ML-AhVC_rsIHWeuBxYQ_AUoAXoECAEQAw"
            />
          </Col>
        </Row>
      </Container>
      </div>
      <DepartLine text="Despre Comuna Dragodana"/>
      <Despre/>
      <DepartLine text="Servicii Online"/>
      <div className="full-width-container">
      <Container style={{ paddingTop: '2.6vh', paddingBottom: '3.4vh' }}>
        <Row>
          <Col md={4}>
            <Card
            />
          </Col>
          <Col md={4}>
            <Card
              imageSrc={imagineGhiseulRo}
              title="Plateste online impozite si taxe"
              text="Descriere despre afacerile din Dragodana."
              buttonText="Intra pe Ghiseul.ro"
              link="https://www.ghiseul.ro/ghiseul/public/"
            />
          </Col>
          <Col md={4}>
            <Card
            />
          </Col>
        </Row>
      </Container>
      </div>
    </>
  );
};

export default Home;
