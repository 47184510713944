import React from 'react';
import DepartLine from '../DepartLine';
import ComponentaHeroSection from '../InformatiiPublice/ComponenteInformatiiPublice/ComponentaHeroSection';
import './Istoric.css'

const Despre = () => {
   return (
    <div className='despre-containter' >
      <ComponentaHeroSection />
      <DepartLine text="Istoricul comunei Dragodana"/>
        <div className='istoric-padder'>
            <p className='istoric-text'>La sfârșitul secolului al XIX-lea, comuna aparținea plășii Cobia din județul Dâmbovița și era alcătuită din cătunele Meri, Moara Popii, Cătunu Păunei și Dragodana, cu o populație totală de 886 de locuitori. Pe teritoriul comunei funcționau două biserici și comuna era cunoscută pentru producția de tutun.</p>
            <p className='istoric-text'>La acea vreme, pe teritoriul actual al comunei, funcționau, în aceeași plasă, și comunele Cuparu și Picior de Munte. Comuna Cuparu avea în compunere satele Burduca și Cuparu, cu 598 de locuitori și în ea funcționau două biserici și o moară de apă. Comuna Picior de Munte avea doar satul de reședință, cu 980 de locuitori, o biserică și o școală.</p>
            <p className='istoric-text'>În 1925, comuna Cuparu fusese desființată și inclusă în comuna Dragodana; aceasta făcea parte din plasa Găești a aceluiași județ și avea acum satele Dragodana, Străoști, Păunei, Meri, Burduca și Cuparu. Comuna Picior de Munte avea tot un singur sat cu 1242 de locuitori, și era inclusă în aceeași plasă.</p>
            <p className='istoric-text'>În 1950, cele două comune au trecut în subordinea raionului Găești din regiunea Argeș. În 1968, comuna Picior de Munte s-a desființat și a fost inclusă în comuna Dragodana, satul Păuna fiind inclus în satul Picior de Munte; de atunci, comuna are componența actuală și face parte din județul Dâmbovița.</p>
        </div>
    </div>
    
  );
};

export default Despre;
