import React from 'react';
import { useSwipeable } from 'react-swipeable';

const SwipeableComponent = ({ children }) => {
  const handlers = useSwipeable({
    onSwipedRight: () => window.history.back(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return <div {...handlers}>{children}</div>;
};

export default SwipeableComponent;
