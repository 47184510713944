import React from 'react';
import './ComponentaPrezentareOm.css';
import imagineProfil from '../../../images/pngwing.png';

function ComponentaPrezentareAdministrator() {
  return (
    <div className="container-om">
      <div className="imagine">
        <img src={imagineProfil} alt="Profil" />
      </div>
      <div className="text center-content">
        <h2 className='nume'>Raluca Stefan</h2>
        <p className="atributii2">Administratorul public are următoarele atribuții principale:</p>
        <ul  className= "lista-atributii">
            <li>Atragerea de fonduri extrabugetare</li>
            <li>Elaborarea de politici și strategii noi</li>
            <li>Coordonarea aparatului de specialitate în lipsa primarului</li>
            <li>Dezvoltarea parteneriatelor</li>
            <li>Reprezentarea instituției primarului</li>
            <li>Pregatirea actelor administrative ale autoritatii executive</li>
            <li>Elaborarea rapoartelor anuale de activitate</li>
            <li>Coordonarea atribuirii contractelor și întocmirea documentațiilor</li>
            <li>Dezvoltarea planului de dezvoltare locală</li>
            <li>Susținerea inițiativelor de dezvoltare locală</li>
            <li>Participarea în grupul de lucru pentru elaborarea și implementarea Sistemului de control managerial</li>
            <li>Întocmirea programelor anuale de achiziții publice</li>
        </ul>
      </div>
    </div>
  );
}

export default ComponentaPrezentareAdministrator;
