import React from 'react';
import AnuntItem from '../InformatiiPublice/ComponenteInformatiiPublice/ComponentaLista';  
import { Row, Col } from 'react-bootstrap';
import './MonitorOficialLocal.css';
import DepartLine from '../DepartLine';

const MonitorOficialLocal = () => {
  const anunturi = [
    {
      title: 'Statutul unității administrativ-teritoriale',
    },
    {
      title: 'Regulamentele procedurilor administrative',
    },
    {
      title: 'Hotărârile autorității deliberative',
    },
    {
    title: 'Dispozițiile autorității executive',
    },
    {
    title: 'Documente și informații financiare',
    },
    {
    title: 'Alte documente',
    },
  ];

  return (
    <div>
      <DepartLine text={'Monitorul Oficial Local'}/>
      <div className='background-color'>
      
        <div className='container-2 padder'>
        <Row style={{paddingTop: '5.6vh'}}>
          <Col xs={12}>
            {anunturi.map((anunt, index) => (
              <AnuntItem key={index} title={anunt.title} description={anunt.description} date={anunt.date} text={anunt.text} />
            ))}
          </Col>
        </Row>
         </div>
      </div>
    </div>
  );
};

export default MonitorOficialLocal;
