import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import logoMobile from '../images/logo.png';
import './NavbarMobile.css';


const NavbarMobile = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(Array(7).fill(false));
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const goBack = () => {
    window.history.back();
  };
    
  const toggleSubMenu = (index) => {
    const newSubMenuOpen = [...subMenuOpen];
    newSubMenuOpen[index] = !newSubMenuOpen[index];
    setSubMenuOpen(newSubMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="mobile-navbar" ref={menuRef}>
      <div className="logo-container-mobile">
      <Link to='/'>
        <img src={logoMobile} className='logo-mobile' alt='Primaria Dragodana' />
      </Link>
    </div>
      <div className="burger-menu" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      {menuOpen && (
        <div className="menu">
          <div className="menu-item" onClick={() => toggleSubMenu(0)}>
            Informații Publice
            {subMenuOpen[0] && (
              <div className="submenu">
                <Link to='/informatii-publice/anunturi'>Anunțuri</Link>
                <Link to='/informatii-publice/declaratii-de-avere'>Declarații de avere</Link>
                <Link to='/informatii-publice/declaratii-de-interese'>Declarații de interese</Link>
                <Link to='/informatii-publice/hotarari'>Hotărâri</Link>
                <Link to='/informatii-publice/protejarea-datelor-cu-caracter-personal'>Protecția datelor cu caracter personal</Link>
              </div>
            )}
          </div>
          <div className="menu-item" onClick={() => toggleSubMenu(1)}>
            Comunitate
            {subMenuOpen[1] && (
              <div className="submenu">
                <Link to='/comunitate/despre'>Despre comuna Dragodana</Link>
                <Link to='/comunitate/istoric'>Istoricul comunei Dragodana</Link>
              </div>
            )}
          </div>
          <div className="menu-item" onClick={() => toggleSubMenu(2)}>
            Alegeri2024
            {subMenuOpen[2] && (
              <div className="submenu">
                <Link to='/alegeri2024/alegeri'>Alegeri2024</Link>
              </div>
            )}
          </div>
          <div className="menu-item" onClick={() => toggleSubMenu(2)}>
            Organizare
            {subMenuOpen[3] && (
              <div className="submenu">
                <Link to='/departamente/departamente'>Compartimente</Link>
                <Link to='/departamente/rof'>Regulament de Organizare și Funcționare</Link>
              </div>
            )}
          </div>
          <div className="menu-item" onClick={() => toggleSubMenu(3)}>
            Administrație
            {subMenuOpen[4] && (
              <div className="submenu">
                <Link to='/administratie/primar'>Primarul</Link>
                <Link to='/administratie/viceprimar'>Viceprimarul</Link>
                <Link to='/administratie/administratorul-public'>Administratorul Public</Link>
                <Link to='/administratie/secretarul-general'>Secretarul General</Link>
                <Link to='/administratie/sna'>STRATEGIA NAȚIONALĂ ANTICORUPȚIE</Link>
                <Link to='/administratie/cod-etica'>Cod Etică</Link>
                <Link to='/administratie/po'>PROCEDURI OPERAȚIONALE</Link>
              </div>
            )}
          </div>
          <div className="menu-item" onClick={() => toggleSubMenu(4)}>
            Consiliu Local
            {subMenuOpen[5] && (
              <div className="submenu">
                <Link to='/consiliu-local/consiliu-declaratii-interese'>Declarații interese consiliu local</Link>
                <Link to='/consiliu-local/consiliu-declaratii-avere'>Declarații avere consiliu local</Link>
                <Link to='/consiliu-local/rapoarte-de-activitate-consilieri'>Rapoarte de activitate consilieri locali</Link>
              </div>
            )}
          </div>
          <div className="menu-item">
            <Link to='/monitorul-oficial-local'>Monitorul Oficial Local</Link>
          </div>
        </div>
      )}

    </div>
  );
};

export default NavbarMobile;
