import React from 'react';
import './ComponentaPrezentareOm.css';
import imagineProfil from '../../../images/primar.png';

function ComponentaPrezentareOm() {
  return (
    <div className="container-om">
      <div className="imagine">
        <img src={imagineProfil} alt="Profil" />
      </div>
      <div className="text center-content">
        <h2 className='nume'>Gheroghe Sandu</h2>
        <p className="atributii2">Primarul are următoarele atribuții principale:</p>
        <ul className= "lista-atributii">
            <li>Reprezentant al statului în condițiile legii;</li>
            <li>Coordonarea relației cu consiliul local;</li>
            <li>Gestionarea bugetului local;</li>
            <li>Supervizarea serviciilor publice pentru cetățeni;</li>
            <li>Alte atribuții stabilite prin lege;</li>
        </ul>
      </div>
    </div>
  );
}

export default ComponentaPrezentareOm;
