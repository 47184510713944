import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-row">
        <div className="footer-col">
        <h3>Primaria comunei</h3>
          <div className="footer-row">
             <h2>Dragodana</h2>
          </div> 
          {/*
            <a href="https://www.facebook.com/PrimariaDragodana" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon className="facebook-square" icon={faFacebookF} />
            </a>
          */}
        </div>
        <div className="footer-col">
          <h3>Date de contact</h3>
          <ul className="contact-list">
            <li>Strada Koșca, nr. 2, com. Dragodana, județul Dâmbovita</li>
            <li>Telefon: 0245 719 189</li>
            <li>Email: pr_dragodana@yahoo.com</li>
          </ul>
        </div>
      </div>
      <hr />
      <div className="footer-row">
        <p className="col-sm">
          &copy; {new Date().getFullYear()} Primaria Dragodana
        </p>
      </div>
    </footer>
  );
};

export default Footer;
