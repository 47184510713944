import React, { useState, useEffect } from "react";
import PhotoModal from "../Organizare/modale/PhotoModalPo";
import '../Organizare/rof.css';
import ComponentaHeroSection from "../InformatiiPublice/ComponenteInformatiiPublice/ComponentaHeroSection";
import DepartLine from "../DepartLine";

const Sna = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const fetchImages = async () => {
    const response = await fetch("/api/po");
    console.log(response);
    const imageList = await response.json();
    setImages(imageList);
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
    <div className="helper">
        <ComponentaHeroSection />
      </div>
    <DepartLine text="Proceduri Operationale"/>
    <div className="sectiune-despre">
        <div className="interior-sectiune-despre">
            <ul className="text-frumos">
                <li>Procedurile Operaționale ale Primăriei</li>
                <li>Definiție Procedurile Operaționale ale Primăriei</li>
                <li>Procedurile operaționale ale primăriei reprezintă un set de instrucțiuni, reguli și protocoale care stabilesc modul în care angajații și funcționarii publici desfășoară activitățile și procesele specifice instituției. Aceste proceduri asigură eficiența, coerența și calitatea activităților, precum și respectarea legislației în vigoare și a standardelor de etică și integritate profesională.</li>
                <li>Procedurile operaționale ale primăriei includ următoarele aspecte:</li>
                <li>Partea I cuprinde: - stabilirea responsabilităților și atribuțiilor angajaților și funcționarilor publici în cadrul instituției - descrierea detaliată a proceselor și activităților specifice primăriei, precum emiterea de autorizații, avize, certificate și alte documente - identificarea resurselor necesare pentru desfășurarea activităților, precum personal, echipamente și materiale.</li>
                <li>Partea a II-a cuprinde: - stabilirea criteriilor și procedurilor de evaluare a performanțelor angajaților și funcționarilor publici - implementarea unor mecanisme de monitorizare și control intern pentru asigurarea calității activităților desfășurate - dezvoltarea unor proceduri de comunicare și colaborare între compartimente și între angajați - promovarea unui mediu de lucru sănătos și sigur și asigurarea respectării normelor de securitate și sănătate în muncă - definirea unor proceduri de raportare a neregulilor, a actelor de corupție și a abuzurilor de putere.</li>
            </ul>
        </div>
    </div>
      <div className="gallery" onClick={openModal}>
        <div className="button">
          <h2>Apasa pentru a vizualiza Procedurile Operationale</h2>
        </div>
      </div>
      {showModal && (
        <PhotoModal
          isOpen={showModal}
          images={images}
          selectedImage={selectedImage}
          onRequestClose={closeModal}
        />
      )}
    </>
  );
};

export default Sna;