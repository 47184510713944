import React from 'react';
import './ComponentaDreapta.css';

function ComponentaDreapta() {
  return (
    <div className="anunturi-dreapta">
      <div className="anunt-item">
        <div className="anunt-summary">
          <h3 className="anunt-item-title">Anunt Agricol</h3>
          <p className="anunt-item-description">
            In perioada 1 martie -15 mai, se desfasoara Campania APIA de primire a cererilor unice de plata 2023
          </p>
        </div>
        <div className="anunt-item-details">
          <p className="anunt-item-date">01.03.2023</p>
        </div>
      </div>
      <div className="anunt-item">
        <div className="anunt-summary">
          <h3 className="anunt-item-title">Anunt Angajare</h3>
          <p className="anunt-item-description">
            Societatea Prospectiuni S.A, are disponibile posturi de MUNCITORI NECALIFICATI, pentru proiectul care se deruleaza pe raza Judetului Dambovita si Arges.
          </p>
        </div>
        <div className="anunt-item-details">
          <p className="anunt-item-date">22.02.2023</p>
        </div>
      </div>
      <div className="anunt-item">
        <div className="anunt-summary">
          <h3 className="anunt-item-title">Anunt Compania de Apa</h3>
          <p className="anunt-item-description">
            In data de 04.04.2023, compania de apa Targoviste Dambovita sectia Gaesti executa lucrari de igienizare al statiei de apa Dragodana.
          </p>
        </div>
        <div className="anunt-item-details">
          <p className="anunt-item-date">31.03.2023</p>
        </div>
      </div>
    </div>
  );
}

export default ComponentaDreapta;
