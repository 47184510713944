import React from 'react';
import ComponentaPrezentareViceprimar from './ComponenteAdministratie/ComponentaPrezentareViceprimar';
import DepartLine from '../DepartLine';
import Card from '../Card';
import { Container, Row, Col } from 'react-bootstrap';
import imagineConstructii from '../../images/constructii.jpeg';
import imagineBiciclete from '../../images/biciclete.jpeg';
import imagineMonument from '../../images/monument.jpeg';

function Viceprimar() {
  return (
    <div>
      <DepartLine text="Viceprimar"/>  
      <ComponentaPrezentareViceprimar />
      <DepartLine text="Evenimente"/>
      <div className="full-width-container">
      <Container style={{ paddingTop: '2.6vh', paddingBottom: '3.4vh' }}>
        <Row>
          <Col md={4}>
            <Card
              imageSrc={imagineConstructii}
              title="Lucrari de reabilitare"
              text="Descriere despre Primaria Orasului Dragodana."
              buttonText="Veziteaza-ne pe Facebook!"
              link="https://www.facebook.com/PrimariaDragodana"
            />
          </Col>
          <Col md={4}>
            <Card
              imageSrc={imagineBiciclete}
              title="Premierea elevilor"
              text="Descriere despre afacerile din Dragodana."
              buttonText="Veziteaza-ne pe Facebook!"
              link="https://www.facebook.com/PrimariaDragodana"
            />
          </Col>
          <Col md={4}>
            <Card
              imageSrc={imagineMonument}
              title="Constructii culturale"
              text="Descriere despre turismul in Dragodana."
              buttonText="Veziteaza-ne pe Facebook!"
              link="https://www.facebook.com/PrimariaDragodana"
            />
          </Col>
        </Row>
      </Container>
      </div>
    </div>
  );
}

export default Viceprimar;