import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import './PhotoModal.css';

Modal.setAppElement("#root");

const PhotoModal = ({ isOpen, images, selectedImage, onRequestClose }) => {
  const [index, setIndex] = useState(selectedImage);

  useEffect(() => {
    setIndex(selectedImage);
  }, [selectedImage]);

  const handlePrevious = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowLeft") {
      handlePrevious();
    } else if (event.key === "ArrowRight") {
      handleNext();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  const handleNext = () => {
    if (index < images.length - 1) {
      setIndex(index + 1);
    }
  };

  const handleDownload = () => {
    const image = images[index];
    const url = `/images/CE/${image}`;
    window.open(url, "_blank");
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      height: "80%",
      maxWidth: "40%",
      overflow: "auto",
    },
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
      <button className="close-button" onClick={onRequestClose}>
        &times;
      </button>
      <div className="modal-image">
        <img src={`/images/CE/${images[index]}`} alt={images[index]} style={{ maxWidth: "70%", height: "100%" }} />
      </div>
      <div className="modal-buttons">
        <button onClick={handlePrevious} disabled={index === 0}>
          Previous
        </button>
        <button onClick={handleNext} disabled={index === images.length - 1}>
          Next
        </button>
        <a href={`/images/CE/${images[index]}`} download>
          <button>Download</button>
        </a>
      </div>
    </Modal>
  );
};

export default PhotoModal;
