import React, { useState } from 'react';
import ComponentaPrezentareOm from './ComponenteAdministratie/ComponentaPrezentareOm';
import DepartLine from '../DepartLine';
import Card from '../Card';
import { Container, Row, Col } from 'react-bootstrap';
import imagineConstructii from '../../images/constructii.jpeg';
import imagineBiciclete from '../../images/biciclete.jpeg';
import imagineMonument from '../../images/monument.jpeg';
import AnuntItem from '../InformatiiPublice/ComponenteInformatiiPublice/ComponentaLista';

function Primar() {

  const [selectedYear, setSelectedYear] = useState("");
  const anunturi = [
    {
      title: "Raport activitate Primar",
      description: "Raport activitate Primar",
      date: "20.03.2023",
      pdfs: ["rapoarte-activitate-consiliu/Raport activitate primar 2023.pdf"],
    },
  ];
  const years = anunturi
    .map((anunt) => anunt.date.split(".")[2])
    .filter((year, index, self) => self.indexOf(year) === index);

  return (
    <div>
      <DepartLine text="Primar"/>  
      <ComponentaPrezentareOm />
      <DepartLine text="Evenimente"/>
      <div className="full-width-container">
      <Container style={{ paddingTop: '2.6vh', paddingBottom: '3.4vh' }}>
        <Row>
          <Col md={4}>
            <Card
              imageSrc={imagineConstructii}
              title="Lucrari de reabilitare"
              text="Descriere despre Primaria Orasului Dragodana."
              buttonText="Veziteaza-ne pe Facebook!"
              link="https://www.facebook.com/PrimariaDragodana"
            />
          </Col>
          <Col md={4}>
            <Card
              imageSrc={imagineBiciclete}
              title="Premierea elevilor"
              text="Descriere despre afacerile din Dragodana."
              buttonText="Veziteaza-ne pe Facebook!"
              link="https://www.facebook.com/PrimariaDragodana"
            />
          </Col>
          <Col md={4}>
            <Card
              imageSrc={imagineMonument}
              title="Constructii culturale"
              text="Descriere despre turismul in Dragodana."
              buttonText="Veziteaza-ne pe Facebook!"
              link="https://www.facebook.com/PrimariaDragodana"
            />
          </Col>
        </Row>
      </Container>
      </div>      
      <DepartLine text="Raport de Activitate"/>
      <div className='container-2'>
      <div className="dropdown-container" >
        <select
          className="dropdown-select"
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
        >
          <option value="">Toate Anunturile</option>
          {years.map((year, index) => (
            <option key={index} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <Row>
        <Col xs={9} md={12}>
          {anunturi
            .filter(
              (anunt) =>
                selectedYear === "" || anunt.date.split(".")[2] === selectedYear
            )
            .map((anunt, index) => (
              <AnuntItem
                key={index}
                title={anunt.title}
                description={anunt.description}
                date={anunt.date}
                text={anunt.text}
                pdfs={anunt.pdfs} 
              />
            ))}
        </Col>
      </Row>
      </div>
    </div>
  );
}

export default Primar;