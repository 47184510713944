import React, { useState, useEffect } from "react";
import './Evenimente.css'

function Evenimente() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch(
      "https://graph.facebook.com/v12.0/581634445297867/feed?fields=attachments,message,created_time&access_token=EAAKtdERyp8gBAHNKklq1eYHMw8gazfNiL7PkOOK9I1XrgdCHxDWdBl9xNZAyLvUN2oAEs9ZAX76MHVNJcVvZBZCr6otGpZBylez2PRgfcU7kiIhzcRIIMLulvyXLqLFV1HqpEOI1200eMP0jvpyiJWv8g7AUsX78z1RZCV4WDI7V1ZAoXgY4buleZATVG34rdjAPGuu4rCNSVUHCflZAO73UA70Si0hPZAG8X1um7RlZCofAaZBq3GOOA8dh&limit"
    )
      .then((response) => response.json())
      .then((data) => setPosts(data.data))
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="grid-container">
      {posts.slice(0, 6).map((post) => (
        <a href={`https://www.facebook.com/${post.id}`} target="_blank" rel="noopener noreferrer">
          <div key={post.id} className="grid-item">
            <div
              className="image"
              style={{ backgroundImage: `url(${post.attachments.data[0].media.image.src})` }}
            ></div>
            <div className="overlay">
              <div className="title">{post.message}</div>
              <div className="date">{new Date(post.created_time).toLocaleDateString()}</div>
            </div>
          </div>
        </a>
      ))}
    </div>
  );
}

export default Evenimente;



