import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import AnuntItem from "../InformatiiPublice/ComponenteInformatiiPublice/ComponentaLista";
import ComponentaHeroSection from "../InformatiiPublice/ComponenteInformatiiPublice/ComponentaHeroSection";
import DepartLine from "../DepartLine";
import "../InformatiiPublice/Anunturi.css";

const Hotarari = () => {
  const [selectedYear, setSelectedYear] = useState("");
  const anunturi = [
    {
        title: "Declaratie interese Chivulescu",
        description: "Declaratie interese Chivulescu",
        date: "16.04.2020",
        pdfs: ["declaratii-interese-consiliu/Chivulescu_interese_redaction.pdf"],
      },
      {
        title: "Declaratie interese David",
        description: "Declaratie interese David",
        date: "16.04.2020",
        pdfs: ["declaratii-interese-consiliu/David_interese_redaction.pdf"],
      },
      {
        title: "Declaratie interese Gerogescu",
        description: "Declaratie interese Gerogescu",
        date: "16.04.2020",
        pdfs: ["declaratii-interese-consiliu/Gerogescu_interese_redaction.pdf"],
      },
      {
        title: "Declaratie interese Ionete",
        description: "Declaratie interese Ionete",
        date: "16.04.2020",
        pdfs: ["declaratii-interese-consiliu/Ionete_interese_redaction.pdf"],
      },
      {
        title: "Declaratie interese Ionita",
        description: "Declaratie interese Ionita",
        date: "16.04.2020",
        pdfs: ["declaratii-interese-consiliu/Ionita_interese_redaction.pdf"],
      },
      {
        title: "Declaratie interese Nastase",
        description: "Declaratie interese Nastase",
        date: "16.04.2020",
        pdfs: ["declaratii-interese-consiliu/Nastase_interese_redaction.pdf"],
      },
      {
        title: "Declaratie interese Nedelcu",
        description: "Declaratie interese Nedelcu",
        date: "16.04.2020",
        pdfs: ["declaratii-interese-consiliu/Nedelcu_interese_redaction.pdf"],
      },
      {
        title: "Declaratie interese Nicolae",
        description: "Declaratie interese Nicolae",
        date: "16.04.2020",
        pdfs: ["declaratii-interese-consiliu/Nicolae_interese_redaction.pdf"],
      },
      {
        title: "Declaratie interese Niculae",
        description: "Declaratie interese Niculae",
        date: "16.04.2020",
        pdfs: ["declaratii-interese-consiliu/Niculae_interese_redaction.pdf"],
      },
      {
        title: "Declaratie interese Popa",
        description: "Declaratie interese Popa",
        date: "16.04.2020",
        pdfs: ["declaratii-interese-consiliu/Popa_interese_redaction.pdf"],
      },
      {
        title: "Declaratie interese Radu",
        description: "Declaratie interese Radu",
        date: "16.04.2020",
        pdfs: ["declaratii-interese-consiliu/Radu_interese_redaction.pdf"],
      },
      {
        title: "Declaratie interese Stan",
        description: "Declaratie interese Stan",
        date: "16.04.2020",
        pdfs: ["declaratii-interese-consiliu/Stan_I_interese_redaction.pdf"],
      },
      {
        title: "Declaratie interese Tudor",
        description: "Declaratie interese Tudor",
        date: "16.04.2020",
        pdfs: ["declaratii-interese-consiliu/Tudor_interese_redaction.pdf"],
      },
      {
        title: "Declaratie interese Voinescu",
        description: "Declaratie interese Voinescu",
        date: "16.04.2020",
        pdfs: ["declaratii-interese-consiliu/Voinescu_interese_redaction.pdf"],
      },
  ];

  const years = anunturi
    .map((anunt) => anunt.date.split(".")[2])
    .filter((year, index, self) => self.indexOf(year) === index);

  return (
    
    <div className="container-aux">
      <div className="helper">
        <ComponentaHeroSection />
      </div>
      <DepartLine text="Declaratii de interese"/>
      <div className="container-2">
      <Row>
        <Col xs={9} md={12}>
          {anunturi
            .filter(
              (anunt) =>
                selectedYear === "" || anunt.date.split(".")[2] === selectedYear
            )
            .map((anunt, index) => (
              <AnuntItem
                key={index}
                title={anunt.title}
                description={anunt.description}
                date={anunt.date}
                text={anunt.text}
                pdfs={anunt.pdfs} 
              />
            ))}
        </Col>
      </Row>
      </div>
    </div>
  );
};

export default Hotarari;
