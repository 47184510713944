import React from 'react';
import DepartLine from '../DepartLine';
import ComponentaHeroSection from '../InformatiiPublice/ComponenteInformatiiPublice/ComponentaHeroSection';
import DespreComponent from '../Despre';
import './Despre.css'

const Despre = () => {
   return (
    <div className='despre-containter' >
      <ComponentaHeroSection />
      <DepartLine text="Despre Comuna Dragodana"/>
      <DespreComponent />
    </div>
  );
};

export default Despre;
