import React, { useState, useEffect } from "react";
import PhotoModal from "../Organizare/modale/PhotoModalCodEtica";
import '../Organizare/rof.css';
import ComponentaHeroSection from "../InformatiiPublice/ComponenteInformatiiPublice/ComponentaHeroSection";
import DepartLine from "../DepartLine";

const Sna = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const fetchImages = async () => {
    const response = await fetch("/api/ce");
    console.log(response);
    const imageList = await response.json();
    setImages(imageList);
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
    <div className="helper">
        <ComponentaHeroSection />
      </div>
    <DepartLine text="Codul de Etica"/>
    <div className="sectiune-despre">
        <div className="interior-sectiune-despre">
            <ul className="text-frumos">
                <li>Codul de etică al primăriei</li>
                <li>Definiție Codul de etică al primăriei</li>
                <li>Codul de etică al primăriei este un set de principii și reguli care guvernează comportamentul funcționarilor publici și al angajaților primăriei în îndeplinirea atribuțiilor și responsabilităților lor. Acesta vizează promovarea integrității, imparțialității și responsabilității profesionale, precum și prevenirea și combaterea corupției și a conflictelor de interese în cadrul instituției.</li>
                <li>Codul de etică al primăriei include următoarele aspecte:</li>
                <li>Partea I cuprinde: - respectarea legalității, echității și transparenței în exercitarea atribuțiilor și responsabilităților - promovarea integrității personale și profesionale și evitarea situațiilor de conflict de interese - asigurarea confidențialității informațiilor și a protecției datelor personale - respectarea principiului nediscriminării și asigurarea egalității de tratament pentru toți cetățenii.</li>
                <li>Partea a II-a cuprinde: - promovarea colaborării și comunicării eficiente între angajați și între angajați și cetățeni - respectarea principiului imparțialității și obiectivității în luarea deciziilor și în soluționarea problemelor - asumarea responsabilității pentru propriile acțiuni și decizii și raportarea oricăror nereguli sau acte de corupție - promovarea transparenței și a accesului la informațiile publice, precum și participarea activă la prevenirea și combaterea corupției în cadrul instituției.</li>
            </ul>
        </div>
    </div>
      <div className="gallery" onClick={openModal}>
        <div className="button">
          <h2>Apasa pentru a vizualiza Codul de Etica</h2>
        </div>
      </div>
      {showModal && (
        <PhotoModal
          isOpen={showModal}
          images={images}
          selectedImage={selectedImage}
          onRequestClose={closeModal}
        />
      )}
    </>
  );
};

export default Sna;