import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import AnuntItem from "./ComponenteInformatiiPublice/ComponentaLista";
import ComponentaHeroSection from "./ComponenteInformatiiPublice/ComponentaHeroSection";
import DepartLine from "../DepartLine";
import "./Anunturi.css";

const Hotarari = () => {
  const [selectedYear, setSelectedYear] = useState("");
  const anunturi = [
    {
        title: "Declaratie Interese Vlasceanu",
        description: "Declaratie Interese Vlasceanu",
        date: "16.04.2020",
        pdfs: ["declaratii-Interese-uat/Vlasceanu_Interese_redaction.pdf"],
      },
      {
        title: "Declaratie Interese Surcel",
        description: "Declaratie Interese Surcel",
        date: "16.04.2020",
        pdfs: ["declaratii-Interese-uat/Surcel_Interese_redaction.pdf"],
      },
      {
        title: "Declaratie Interese Stoican",
        description: "Declaratie Interese Stoican",
        date: "16.04.2020",
        pdfs: ["declaratii-Interese-uat/Stoican_Interese_redaction.pdf"],
      },
      {
        title: "Declaratie Interese Stan",
        description: "Declaratie Interese Stan",
        date: "16.04.2020",
        pdfs: ["declaratii-Interese-uat/Stan_Interese_redaction.pdf"],
      },
      {
        title: "Declaratie Interese Serban",
        description: "Declaratie Interese Serban",
        date: "16.04.2020",
        pdfs: ["declaratii-Interese-uat/Serban_Interese_redaction.pdf"],
      },
      {
        title: "Declaratie Interese Sandu",
        description: "Declaratie Interese Sandu",
        date: "16.04.2020",
        pdfs: ["declaratii-Interese-uat/Sandu_Interese_redaction.pdf"],
      },
      {
        title: "Declaratie Interese Paun",
        description: "Declaratie Interese Paun",
        date: "16.04.2020",
        pdfs: ["declaratii-Interese-uat/Paun_Interese_redaction.pdf"],
      },
      {
        title: "Declaratie Interese Pandele",
        description: "Declaratie Interese Pandele",
        date: "16.04.2020",
        pdfs: ["declaratii-Interese-uat/Pandele_Interese_redaction.pdf"],
      },
      {
        title: "Declaratie Interese Lita",
        description: "Declaratie Interese Lita",
        date: "16.04.2020",
        pdfs: ["declaratii-Interese-uat/Lita_Interese_redaction.pdf"],
      },
      {
        title: "Declaratie Interese Linca",
        description: "Declaratie Interese Linca",
        date: "16.04.2020",
        pdfs: ["declaratii-interese-uat/Linca_Interese_redaction.pdf"],
      },
      {
        title: "Declaratie Interese Dumitru",
        description: "Declaratie Interese Dumitru",
        date: "16.04.2020",
        pdfs: ["declaratii-Interese-uat/Dumitru_Interese_redaction.pdf"],
      },
      {
        title: "Declaratie Interese Balan",
        description: "Declaratie Interese Balan",
        date: "16.04.2020",
        pdfs: ["declaratii-Interese-uat/Balan_Interese_redaction.pdf"],
        },
        {
        title: "Declaratie Interese 3_Stefan",
        description: "Declaratie Interese 3_Stefan",
        date: "16.04.2020",
        pdfs: ["declaratii-Interese-uat/3_Stefan_Interese_redaction.pdf"],
        },
        {
        title: "Declaratie Interese 2_Viceprimar",
        description: "Declaratie Interese 2_Viceprimar",
        date: "16.04.2020",
        pdfs: ["declaratii-Interese-uat/2_Viceprimar_Interese_redaction.pdf"],
        },
        {
        title: "Declaratie Interese 1_Primar",
        description: "Declaratie Interese 1_Primar",
        date: "16.04.2020",
        pdfs: ["declaratii-Interese-uat/1_Primar_Interese_redaction.pdf"],
        },
  ];

  const years = anunturi
    .map((anunt) => anunt.date.split(".")[2])
    .filter((year, index, self) => self.indexOf(year) === index);

  return (
    
    <div className="container-aux">
      <div className="helper">
        <ComponentaHeroSection />
      </div>
      <DepartLine text="Declaratii de Interese"/>
      <div className="container-2">
      <Row>
        <Col xs={9} md={12}>
          {anunturi
            .filter(
              (anunt) =>
                selectedYear === "" || anunt.date.split(".")[2] === selectedYear
            )
            .map((anunt, index) => (
              <AnuntItem
                key={index}
                title={anunt.title}
                description={anunt.description}
                date={anunt.date}
                text={anunt.text}
                pdfs={anunt.pdfs} 
              />
            ))}
        </Col>
      </Row>
      </div>
    </div>
  );
};

export default Hotarari;
