import React, { useState, useEffect } from "react";
import PhotoModal from "../Organizare/modale/PhotoModalSna";
import '../Organizare/rof.css';
import ComponentaHeroSection from "../InformatiiPublice/ComponenteInformatiiPublice/ComponentaHeroSection";
import DepartLine from "../DepartLine";

const Sna = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const fetchImages = async () => {
    const response = await fetch("/api/sna");
    console.log(response);
    const imageList = await response.json();
    setImages(imageList);
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
    <div className="helper">
        <ComponentaHeroSection />
      </div>
    <DepartLine text="Strategia Nationala Anticoruptie"/>
    <div className="sectiune-despre">
        <div className="interior-sectiune-despre">
            <ul className="text-frumos">
                <li>Strategia Națională Anticorupție</li>
                <li>Definiție Strategia Națională Anticorupție</li>
                <li>Prescurtat - SNA, este un plan de acțiune dezvoltat de către guvern și autoritățile naționale pentru a combate și preveni corupția în toate sectoarele societății. Aceasta strategie are drept scop consolidarea integrității, transparenței și responsabilității instituțiilor publice, precum și promovarea unor mecanisme eficiente de prevenire, detectare și sancționare a actelor de corupție.</li>
                <li>SNA are mai multe obiective principale, printre care:</li>
                <li>Partea I cuprinde: - consolidarea cadrului legal și instituțional în domeniul anticorupție - dezvoltarea unui sistem eficient de prevenire a corupției în sectorul public și privat - promovarea integrității și eticii profesionale în rândul funcționarilor publici și a angajaților din sectorul privat - îmbunătățirea transparenței și accesului la informații publice - consolidarea mecanismelor de control și audit intern și extern.</li>
                <li>Partea a II-a cuprinde: - intensificarea eforturilor de combatere a corupției și a infracțiunilor conexe, prin cooperarea între instituții și agenții responsabile de investigarea și sancționarea acestor infracțiuni - dezvoltarea unui sistem eficient de protecție a denunțătorilor de corupție și a victimelor acestui fenomen - promovarea educației și conștientizării publice cu privire la corupție și la consecințele sale negative asupra societății - consolidarea cooperării internaționale și regionale în lupta împotriva corupției.</li>
            </ul>
        </div>
    </div>
      <div className="gallery" onClick={openModal}>
        <div className="button">
          <h2>Apasa pentru a vizualiza Strategia Nationala Anticoruptie</h2>
        </div>
      </div>
      {showModal && (
        <PhotoModal
          isOpen={showModal}
          images={images}
          selectedImage={selectedImage}
          onRequestClose={closeModal}
        />
      )}
    </>
  );
};

export default Sna;