import React from 'react';
import { Card, Button } from 'react-bootstrap';
import './Card.css';

const CardComponent = ({ title, description, buttonText, imageSrc, link}) => {

  const handleClick = () => {
    window.open(link, '_blank');
  };

  return (
    <Card className="custom-card">
      <Card.Img className="card-image" variant="top" src={imageSrc} />
      <div className="card-overlay"></div>
      <Card.Body className="card-body">
        <Card.Title className="card-title">{title}</Card.Title>
        <Card.Text className="card-description">{description}</Card.Text>
        <Button className="card-button" variant="outline-light" onClick={handleClick}>
          {buttonText}
        </Button>
      </Card.Body>
    </Card>
  );
};

export default CardComponent;
