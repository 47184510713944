import React, { useState, useEffect } from "react";
import PhotoModal from "../Organizare/modale/PhotoModalGdpr";
import '../Organizare/rof.css';
import ComponentaHeroSection from "../InformatiiPublice/ComponenteInformatiiPublice/ComponentaHeroSection";
import DepartLine from "../DepartLine";

const ProtectiaDatelor = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const fetchImages = async () => {
    const response = await fetch("/api/gdpr");
    console.log(response);
    const imageList = await response.json();
    setImages(imageList);
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
    <div className="helper">
        <ComponentaHeroSection />
      </div>
    <DepartLine text="Protectia datelor cu caracter personal"/>
    <div className="sectiune-despre">
        <div className="interior-sectiune-despre">
            <ul className="text-frumos">
                <li className="text-subheader">Regulamentul General privind Protecția Datelor:</li>
                <ul>
                  <li className="text-list">Definiție Protecția Datelor</li>
                  <li className="text-list">Prescurtat - GDPR (General Data Protection Regulation), este un cadru legislativ la nivelul Uniunii Europene care reglementează modul în care organizațiile colectează, stochează, procesează și protejează datele personale ale cetățenilor europeni.</li>
                  <li className="text-list">GDPR are mai multe obiective principale, printre care:</li>
                  <li className="text-list">Partea I cuprinde: - consolidarea drepturilor persoanelor vizate prin introducerea unor drepturi noi și extinderea celor existente - responsabilizarea organizațiilor care procesează date personale prin obligații clare și transparente - armonizarea legislației privind protecția datelor în întreaga UE - facilitarea cooperării între autoritățile de supraveghere și asigurarea unui nivel înalt de protecție a datelor personale în cadrul UE.</li>
                  <li className="text-list">Partea a II-a cuprinde principiile GDPR, care includ: - legalitatea, echitatea și transparența în procesarea datelor personale - limitarea scopului procesării datelor personale, asigurându-se că sunt colectate și utilizate numai în scopuri specifice, explicite și legitime - minimizarea datelor, colectând și procesând doar datele personale necesare în raport cu scopurile pentru care sunt procesate - exactitatea, păstrând datele personale actualizate și corecte - limitarea stocării, păstrând datele personale numai cât este necesar în raport cu scopurile pentru care sunt procesate - integritatea și confidențialitatea, asigurându-se că datele personale sunt protejate împotriva accesului neautorizat, pierderii, distrugerii sau divulgării.</li>
                </ul>
            </ul>
        </div>
    </div>
      <div className="gallery" onClick={openModal}>
        <div className="button">
          <h2>Apasa pentru a vizualiza Protectia Datelor cu Caracter Personal</h2>
        </div>
      </div>
      {showModal && (
        <PhotoModal
          isOpen={showModal}
          images={images}
          selectedImage={selectedImage}
          onRequestClose={closeModal}
        />
      )}
    </>
  );
};

export default ProtectiaDatelor;