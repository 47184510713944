import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ComponentaHeroSection.css';
import banner from '../../../images/banner2.jpeg';
import banner1 from '../../../images/biciclete.jpeg';
import banner2 from '../../../images/monument.jpeg';

const ComponentaHeroSection = () => {
  const settings = {
    infinite: true,
    speed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0.0001,
    pauseOnHover: false
  };

  return (
    <div className="anunturi-banner-container">
      <div className="anunturi-banner">
        <Slider {...settings}>
          <div>
            <img src={banner} alt="Banner 1" className='hero-image' />
          </div>
          <div>
            <img src={banner1} alt="Banner 2" className='hero-image'/>
          </div>
          <div>
            <img src={banner2} alt="Banner 3" className='hero-image'/>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default ComponentaHeroSection;
