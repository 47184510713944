import React, { useState, useEffect } from "react";
import PhotoModal from "./modale/PhotoModalDepartamente";
import './rof.css';
import ComponentaHeroSection from "../InformatiiPublice/ComponenteInformatiiPublice/ComponentaHeroSection";
import DepartLine from "../DepartLine";

const ROF = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const fetchImages = async () => {
    const response = await fetch("/api/departamente");
    const imageList = await response.json();
    setImages(imageList);
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
    <div className="helper">
        <ComponentaHeroSection />
      </div>
    <DepartLine text="Compartimente"/>
    <div className="sectiune-despre">
     <div className="interior-sectiune-despre">
        <ul className="text-frumos">
            <li className="text-header">
              Serviciile şi compartimentele care intră în alcătuirea aparatului de specialitate al Primarului Comunei Dragodana sunt următoarele:
            </li>
            <ul>
              <li className="text-subheader">Subordonate direct primarului:</li>
              <ul>
                <li className="text-list">COMPARTIMENT FINANCIAR- CONTABILITATE</li>
                <li className="text-list">COMPARTIMENT REGISTRU AGRICOL ȘI FOND FUNCIAR</li>
                <li className="text-list">COMPARTIMENT IMPOZITE SI TAXE</li>
                <li className="text-list">COMPARTIMENT ACHIZIȚII PUBLICE</li>
                <li className="text-list">COMPARTIMENT STARE CIVILĂ, RESURSE UMANE SI RELAȚII CU PUBLICUL</li>
                <li className="text-list"> SERVICIUL VOLUNTAR PENTRU SITUAȚII DE URGENȚĂ</li>
              </ul>
            </ul>
            <ul>
              <li className="text-subheader">Subordonate viceprimarului:</li>
              <ul>
                <li className="text-list">COMPARTIMENT URBANISM SI AMENAJAREA TERITORIULUI</li>
                <li className="text-list">COMPARTIMENT PAZĂ ȘI ÎNTREȚINERE</li>
                <li className="text-list">COMPARTIMENT CULTURĂ</li>
              </ul>
            </ul>
            <ul>
              <li className="text-subheader">Subordonat secretarului comunei:</li>
              <ul>
                <li className="text-list">COMPARTIMENT JURIDIC</li>
              </ul>
            </ul>
        </ul>
     </div>
    </div>
      <div className="gallery" onClick={openModal}>
        <div className="button">
          <h2>Apasa pentru a vizualiza Organigrama si Regulamentul</h2>
        </div>
      </div>
      {showModal && (
        <PhotoModal
          isOpen={showModal}
          images={images}
          selectedImage={selectedImage}
          onRequestClose={closeModal}
        />
      )}
    </>
  );
};

export default ROF;
