import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import AnuntItem from "./ComponenteInformatiiPublice/ComponentaLista";
import ComponentaDreapta from "./ComponenteInformatiiPublice/ComponentaDreapta";
import ComponentaHeroSection from "./ComponenteInformatiiPublice/ComponentaHeroSection";
import DepartLine from "../DepartLine";
import "./Anunturi.css";

const Hotarari = () => {
  const [selectedYear, setSelectedYear] = useState("");
  const anunturi = [
    {
      title: "Hotarare 02.11.2022",
      description: "Hotarare privind aprobarea actualizarii indicatorilor tehnico economici si a devizului",
      date: "02.11.2022",
      pdfs: ["hotarari/2.11.2022.pdf"],
    },
    {
      title: "Hotarare 13.02.2022",
      description: "Hotarare privind aprobarea bugetului local pe anul 2022",
      date: "13.02.2022",
      pdfs: ["hotarari/13.02.2022.pdf"],
    },
    {
      title: "Hotarare 31.08.2018",
      description: "Hotarare cu privire la aprobarea Nomenclatorului Stradal al comunei Dragodana",
      date: "31.08.2018",
      pdfs: ["hotarari/31.08.2018 (1).pdf"],
    },
    {
      title: "Hotarare 09.05.2022",
      description: "Proiect de Hotarare cu privire la completarea bunurilor care apartin domeniului public",
      date: "09.05.2022",
      pdfs: ["hotarari/1005.pdf"],
    },
    {
      title: "Hotarare 26.05.2022",
      description: "Hotarare cu privire la completarea bunurilor care apartin domeniului public al comunei Dragodana",
      date: "26.05.2022",
      pdfs: ["hotarari/2705.pdf"],
    },
    {
      title: "Hotarare 04.10.2022",
      description: "Hotarare privind aprobarea actualizarii indicatorilor tehnico economici si a devizului",
      date: "04.10.2022",
      pdfs: ["hotarari/04.10.2022.PDF"],
    },
  ];

  const years = anunturi
    .map((anunt) => anunt.date.split(".")[2])
    .filter((year, index, self) => self.indexOf(year) === index);

  return (
    
    <div className="container-aux">
      <div className="helper">
        <ComponentaHeroSection />
      </div>
      <DepartLine text="Hotarari"/>
      <div className="container-2">
      <div className="dropdown-container">
        <select
          className="dropdown-select"
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
        >
          <option value="">Selecteaza un an</option>
          {years.map((year, index) => (
            <option key={index} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <Row>
        <Col xs={9} md={12}>
          {anunturi
            .filter(
              (anunt) =>
                selectedYear === "" || anunt.date.split(".")[2] === selectedYear
            )
            .map((anunt, index) => (
              <AnuntItem
                key={index}
                title={anunt.title}
                description={anunt.description}
                date={anunt.date}
                text={anunt.text}
                pdfs={anunt.pdfs} 
              />
            ))}
        </Col>
      </Row>
      </div>
    </div>
  );
};

export default Hotarari;
