import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../images/logo.png';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
      setIsOpen(!isOpen);
  };

  return (
    <nav className='ceva navbar navbar-expand-lg fixed-top'>
      <div className='container-fluid'>
        <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarNav'
            aria-controls='navbarNav'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={handleToggle}
          >
            {!isOpen ? (<span className='navbar-toggler-icon'></span>) : (<span className='navbar-close-icon'>&#10005;</span>)}
        </button>
        <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`} id="navbarNav">
        <div className='collapse navbar-collapse' id='navbarNav'>
            <ul className='navbar-nav nav-fill w-100 dropdown-container'>
              <li className='nav-item dropdown'>
                <Link
                  to='/informatii-publice'
                  className='nav-link'
                  id='navbarDropdown'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  Informații Publice
                </Link>
                <ul className='dropdown-menu exemplu ' aria-labelledby='navbarDropdown'>
                <li>
                    <Link to='/informatii-publice/anunturi' className='dropdown-item'>
                      Anunțuri
                    </Link>
                </li>
                <li>
                    <Link to='/informatii-publice/declaratii-de-avere' className='dropdown-item'>
                      Declarații de avere
                    </Link>
                </li>
                <li>
                    <Link to='/informatii-publice/declaratii-de-interese' className='dropdown-item'>
                      Declarații de interese
                    </Link>
                </li>
                <li>
                    <Link to='/informatii-publice/hotarari' className='dropdown-item'>
                      Hotărâri
                    </Link>
                </li>
                <li>
                    <Link to='/informatii-publice/protejarea-datelor-cu-caracter-personal' className='dropdown-item'>
                      Protecția datelor cu caracter personal
                    </Link>
                </li>
                </ul>
              </li>
              <li className='nav-item dropdown'>
                <Link
                  to='/informatii-publice'
                  className='nav-link'
                  id='navbarDropdown'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  Alegeri 2024
                </Link>
                <ul className='dropdown-menu exemplu ' aria-labelledby='navbarDropdown'>
                <li>
                    <Link to='/alegeri2024/alegeri' className='dropdown-item'>
                      BIROUL ELECTORAL DE CIRCUMSCRIPȚIE ELECTORALĂ NR. 34 DRAGODANA
                    </Link>
                </li>
                </ul>
              </li>
              <li className='nav-item dropdown'>
                <Link
                  to='/comunitate'
                  className='nav-link'
                  id='navbarDropdown'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  Comunitate
                </Link>
                <ul className='dropdown-menu' aria-labelledby='navbarDropdown'>
                  <li>
                    <Link to='/comunitate/despre' className='dropdown-item'>
                      Despre comuna Dragodana
                    </Link>
                  </li>
                  <li>
                    <Link to='/comunitate/istoric' className='dropdown-item'>
                      Istoricul comunei Dragodana
                    </Link>
                  </li>
                </ul>
              </li>
              <li className='nav-item dropdown'>
                <Link
                  to='/departamente'
                  className='nav-link'
                  id='navbarDropdown'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  Organizare
                </Link >
                <ul className='dropdown-menu' aria-labelledby='navbarDropdown'>
                  <li>
                    <Link to='/departamente/departamente' className='dropdown-item'>
                      Compartimente
                    </Link>
                  </li>
                  <li>
                    <Link to='/departamente/rof' className='dropdown-item'>
                      Regulament de Organizare și Funcționare
                    </Link>
                  </li>
                </ul>
              </li>
              <li className='logo-item'>
                <Link to='/' className='navbar-brand'>
                Primăria
                <img src={logo}  className='logo' alt='Primaria Dragodana' />
                Dragodana
                </Link>
              </li>            
              <li className='nav-item dropdown '>
                <Link
                  to='/administratie'
                  className='nav-link  nav-item-bis'
                  id='navbarDropdown'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  Administrație
                </Link>
                <ul className='dropdown-menu dropdown-menu-right' aria-labelledby='navbarDropdown'>
                  <li>
                    <Link to='/administratie/primar' className='dropdown-item'>
                      Primarul
                    </Link>
                  </li>
                  <li>
                    <Link to='/administratie/viceprimar' className='dropdown-item'>
                      Viceprimarul
                    </Link>
                  </li>
                  <li>
                    <Link to='/administratie/administratorul-public' className='dropdown-item'>
                      Administratorul Public
                    </Link>
                  </li>
                  <li>
                    <Link to='/administratie/secretarul-general' className='dropdown-item'>
                      Secretarul General
                    </Link>
                  </li>
                  <li>
                    <Link to='/administratie/sna' className='dropdown-item'>
                      STRATEGIA NAȚIONALĂ ANTICORUPȚIE
                    </Link>
                  </li>
                  <li>
                    <Link to='/administratie/cod-etica' className='dropdown-item'>
                      Cod Etică
                    </Link>
                  </li>
                  <li>
                    <Link to='/administratie/po' className='dropdown-item'>
                      PROCEDURI OPERAȚIONALE
                    </Link>
                  </li>
                </ul>
              </li>
              <li className='nav-item dropdown'>
                <Link
                  to='/consiliu-local'
                  className='nav-link'
                  id='navbarDropdown'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  Consiliu Local
                </Link>
                <ul className='dropdown-menu dropdown-menu-right' aria-labelledby='navbarDropdown'>
                  <li>
                    <Link to='/consiliu-local/consiliu-declaratii-interese' className='dropdown-item'>
                      Declarații interese consiliu local
                    </Link>
                  </li>
                  <li>
                    <Link to='/consiliu-local/consiliu-declaratii-avere' className='dropdown-item'>
                      Declarații avere consiliu local
                    </Link>
                  </li>
                  <li>
                    <Link to='/consiliu-local/rapoarte-de-activitate-consilieri' className='dropdown-item'>
                      Rapoarte de activitate consilieri locali
                    </Link>
                  </li>
                </ul>
              </li>
              <li className='nav-item'>
                <Link to='/monitorul-oficial-local' className='nav-link nav-item-bis-remove'>
                  Monitorul Oficial Local
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
