import React, { useState, useEffect } from "react";
import PhotoModal from "./modale/PhotoModal";
import './rof.css';
import ComponentaHeroSection from "../InformatiiPublice/ComponenteInformatiiPublice/ComponentaHeroSection";
import DepartLine from "../DepartLine";

const ROF = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const fetchImages = async () => {
    const response = await fetch("/api/images");
    const imageList = await response.json();
    setImages(imageList);
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
    <div className="helper">
        <ComponentaHeroSection />
      </div>
    <DepartLine text="Regulament de organizare si functionare"/>
    <div className="sectiune-despre">
      <div className="interior-sectiune-despre"> 
      <ul className="text-frumos">
        <li className="text-subheader">Regulament de organizare si functionare:</li>
        <ul>
          <li className="text-list">Definitie Regulament de organizare si functionare</li>
          <li className="text-list">Prescurtat - ROF Instrument de organizare folosit pentru descrierea detaliata a structurii organizatorice a unui sistem economico-social, in vederea cunoasterii si analizei sale aprofundate.</li>
          <li className="text-list">De regula ROF este structurat in doua subdiviziuni principale:</li>
          <li className="text-list">Partea I cuprinde: - informatii privind baza legala a constituirii si functionarii sistemului respectiv - o succinta prezentare a obiectului de activitate - organigrama generala - organigrame partiale ale principalelor componente ale organizarii structurale (daca este necesar) - prezentari detaliate ale principalelor caracteristici organizationale (obiective specifice sau individuale, sarcini, autoritate si responsabilitati ale conducerii colective si individuale).</li>
          <li className="text-list">Partea a II-a cuprinde descrierea in detaliu a compartimentelor si a functiilor incorporate, pentru primele prezentandu-se obiectivele si atributiile acestora. Descrierile organizatorice ale compartimentelor se efectueaza potrivit succesiunii lor in organigrama, acestea grupandu-se pe module corespunzator subordonarii conducerii superioare a sistemului economico-social respectiv.</li>
        </ul>
      </ul>
      </div>
    </div>
      <div className="gallery" onClick={openModal}>
        <div className="button">
          <h2>Apasa pentru a vizualiza Regulamentul de organizare si functionare</h2>
        </div>
      </div>
      {showModal && (
        <PhotoModal
          isOpen={showModal}
          images={images}
          selectedImage={selectedImage}
          onRequestClose={closeModal}
        />
      )}
    </>
  );
};

export default ROF;
