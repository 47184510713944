import React from 'react';
import './ComponentaPrezentareOm.css';
import imagineProfil from '../../../images/pngwing.png';

function ComponentaPrezentareViceprimar() {
  return (
    <div className="container-om">
      <div className="imagine">
        <img src={imagineProfil} alt="Profil" />
      </div>
      <div className="text">
        <h2  className='nume center-content'>Laurentiu - Florin MIHALACHE</h2>
        <p className="atributii2">Viceprimarul are următoarele atribuții principale:</p>
        <ul className= "lista-atributii">
            <li>Coordonarea activității personalului și serviciilor publice;</li>
            <li>Implementarea măsurilor necesare în domeniul gospodăririi localității;</li>
            <li>Gestionarea deșeurilor și a problemelor de mediu;</li>
            <li>Supervizarea și controlul lucrărilor și activităților publice;</li>
            <li>Participarea la comisii și comitete locale;</li>
            <li>Administrarea și amenajarea spațiilor publice;</li>
            <li>Soluționarea petițiilor și efectuarea de constatări în teren;</li>
            <li>Controlul agenților economici și respectarea legislației;</li>
            <li>Înlocuirea primarului în lipsa acestuia;</li>
            <li>Elaborarea și prezentarea rapoartelor de activitate;</li>
            <li>Aplicarea sancțiunilor în domeniul gospodăririi localității și mediului;</li>
            <li>Exercitarea altor atribuții conform legislației și sarcinilor primite.</li>
        </ul>
      </div>
    </div>
  );
}

export default ComponentaPrezentareViceprimar;
