import React from 'react';
import './Banner.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { faPeopleLine } from '@fortawesome/free-solid-svg-icons';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { faSchool } from '@fortawesome/free-solid-svg-icons';
import { faGavel } from '@fortawesome/free-solid-svg-icons';
import { faScaleBalanced } from '@fortawesome/free-solid-svg-icons';
import { faBuildingColumns } from '@fortawesome/free-solid-svg-icons';

const openLink = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

const Banner = () => {
  return (
    <div className='banner-home'>
      <div className='banner'>

      </div>
      <div className="icon-banner">
      <div className="column-icon">
        <div className="icon-text-container" onClick={() => openLink('https://www.ghiseul.ro/ghiseul/public/')}>
          <div class="icon-container">
          <FontAwesomeIcon icon={faInbox}  style={{ color: 'white', filter: 'drop-shadow(0px 0px 0px black)', stroke: '#37475A', strokeWidth: '20px'  }} />
          </div>
          <div class="text-container">
            <h3>Ghiseul.ro</h3>
          </div>
        </div>

        <div className="icon-text-container" onClick={() => openLink('https://www.facebook.com/groups/dragodana')}>
          <div class="icon-container">
          <FontAwesomeIcon icon={faPeopleLine}  style={{ color: 'white', filter: 'drop-shadow(0px 0px 0px black)', stroke: '#37475A', strokeWidth: '20px'  }} />
          </div>
          <div class="text-container">
            <h3>Comunitatea comunei Dragodana</h3>
          </div>
        </div>

        <div className="icon-text-container" onClick={() => openLink('/monitorul-oficial-local')}>
          <div class="icon-container">
          <FontAwesomeIcon icon={faNewspaper}  style={{ color: 'white', filter: 'drop-shadow(0px 0px 0px black)', stroke: '#37475A', strokeWidth: '20px'  }}/>
          </div>
          <div class="text-container">
            <h3>Monitorul Oficial</h3>
          </div>
        </div>

        <div className="icon-text-container" onClick={() => openLink('')}>
          <div class="icon-container">
          <FontAwesomeIcon icon={faEnvelope}  style={{ color: 'white', filter: 'drop-shadow(0px 0px 0px black)', stroke: '#37475A', strokeWidth: '20px'  }}/>
          </div>
          <div class="text-container">
            <h3>Scrie-ne!</h3>
          </div>
        </div>

        <div className="icon-text-container" onClick={() => openLink('/departamente/departamente')}>
          <div class="icon-container">
          <FontAwesomeIcon icon={faBuildingColumns}  style={{ color: 'white', filter: 'drop-shadow(0px 0px 0px black)', stroke: '#37475A', strokeWidth: '20px'  }}/>
          </div>
          <div class="text-container">
            <h3>Departamentele Primariei</h3>
          </div>
        </div>
      </div>
      <div className="column-icon">
      <div className="icon-text-container" onClick={() => openLink('/administratie/primar')}>
          <div class="icon-container">
          <FontAwesomeIcon icon={faIdCard}  style={{ color: 'white', filter: 'drop-shadow(0px 0px 0px black)', stroke: '#37475A', strokeWidth: '20px'  }}/>
          </div>
          <div class="text-container">
            <h3>Primarul</h3>
          </div>
        </div>

        <div className="icon-text-container" onClick={() => openLink('/departamente/rof')}>
          <div class="icon-container">
          <FontAwesomeIcon icon={faScaleBalanced}  style={{ color: 'white', filter: 'drop-shadow(0px 0px 0px black)', stroke: '#37475A', strokeWidth: '20px'  }}/>
          </div>
          <div class="text-container">
            <h3>Regulamentul de functionare</h3>
          </div>
        </div>

        <div className="icon-text-container" onClick={() => openLink('/informatii-publice/anunturi')}>
          <div class="icon-container">
          <FontAwesomeIcon icon={faList}  style={{ color: 'white', filter: 'drop-shadow(0px 0px 0px black)', stroke: '#37475A', strokeWidth: '20px' }}/>
          </div>
          <div class="text-container">
            <h3>Anunturi</h3>
          </div>
        </div>

        <div className="icon-text-container" onClick={() => openLink('/informatii-publice/hotarari')}>
          <div class="icon-container">
          <FontAwesomeIcon icon={faGavel}  style={{ color: 'white', filter: 'drop-shadow(0px 0px 0px black)', stroke: '#37475A', strokeWidth: '20px'  }}/>
          </div>
          <div class="text-container">
            <h3>Transparenta Decizionala</h3>
          </div>
        </div>

        <div className="icon-text-container" onClick={() => openLink('')}>
          <div class="icon-container">
          <FontAwesomeIcon icon={faSchool}  style={{ color: 'white', filter: 'drop-shadow(0px 0px 0px black)', stroke: '#37475A', strokeWidth: '20px'  }}/>
          </div>
          <div class="text-container">
            <h3>Invatamant si educatie</h3>
          </div>
        </div>
      </div>   
      </div>       
    </div>
  );
};

export default Banner;
