import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import AnuntItem from "./ComponenteInformatiiPublice/ComponentaLista";
import ComponentaDreapta from "./ComponenteInformatiiPublice/ComponentaDreapta";
import ComponentaHeroSection from "./ComponenteInformatiiPublice/ComponentaHeroSection";
import DepartLine from "../DepartLine";
import "./Anunturi.css";

const Anunturi = () => {
  const [selectedYear, setSelectedYear] = useState("");
  const anunturi = [
    {
      title: "Anunt 13.05.2022",
      description: "Institutia Primarului comunei Dragodana, judetul Dambovita, organizeaza concurs in perioada 08.06.2022 - 10.06.2022",
      date: "13.05.2022",
      pdfs: ["anunturi/anunt_13.05.2022.pdf"],
    },
    {
      title: "Anunt 18.01.2022",
      description: "Anunt organizare concurs de recrutare inspector, grad profesional debutant",
      date: "18.01.2022",
      pdfs: ["anunturi/anunt_18.01.2022.pdf"],
    },
    {
      title: "Anunt 01.03.2023",
      description: "Anunt organizare concurs de recrutare referent, grad profesion superior",
      date: "01.03.2023",
      pdfs: ["anunturi/anunt_01.03.2023.pdf"],
    },
    {
      title: "Anunt 03.02.2023",
      description: "Anunt organizare concurs de recrutare referent, grad profesion superior",
      date: "03.02.2023",
      pdfs: ["anunturi/anunt_03.02.2023.pdf"],
    },
    {
      title: "Anunt 02.10.2023",
      description: "Anunt organizare concurs de recrutare șef în cadrul Serviciului Voluntar pentru Sitații de Urgență",
      date: "02.10.2023",
      pdfs: ["anunturi/anunt_02.10.2023.pdf"],
    },
    {
      title: "Anunt PNRR",
      description: "PNRR: Fonduri pentru România modernă și reformată",
      date: "08.01.2024",
      pdfs: ["anunturi/AnuntPnrr.jpeg"],
    },
    {
      title: "Anunt 01.03.2024",
      description: "Anunt organizare concurs de recrutare 01.03.2024",
      date: "01.03.2024",
      pdfs: ["anunturi/anunt_01.03.2024.pdf"],
    },
  ];

  const years = anunturi
    .map((anunt) => anunt.date.split(".")[2])
    .filter((year, index, self) => self.indexOf(year) === index);

  return (
    
    <div className="container-aux">
      <div className="helper">
        <ComponentaHeroSection />
      </div>
      <DepartLine text="Anunturi"/>
      <div className="container-2">
      <div className="dropdown-container">
        <select
          className="dropdown-select"
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
        >
          <option value="">Toate Anunturile</option>
          {years.map((year, index) => (
            <option key={index} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <Row>
        <Col xs={12} md={8}>
          {anunturi
            .filter(
              (anunt) =>
                selectedYear === "" || anunt.date.split(".")[2] === selectedYear
            )
            .map((anunt, index) => (
              <AnuntItem
                key={index}
                title={anunt.title}
                description={anunt.description}
                date={anunt.date}
                text={anunt.text}
                pdfs={anunt.pdfs} 
              />
            ))}
        </Col>
        <Col xs={12} md={4}>
          <ComponentaDreapta />
        </Col>
      </Row>
      </div>
    </div>
  );
};

export default Anunturi;
