import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import AnuntItem from "./ComponenteInformatiiPublice/ComponentaLista";
import ComponentaHeroSection from "./ComponenteInformatiiPublice/ComponentaHeroSection";
import DepartLine from "../DepartLine";
import "./Anunturi.css";

const Hotarari = () => {
  const [selectedYear, setSelectedYear] = useState("");
  const anunturi = [
    {
        title: "Declaratie avere Vlasceanu",
        description: "Declaratie avere Vlasceanu",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-uat/Vlasceanu_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Surcel",
        description: "Declaratie avere Surcel",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-uat/Surcel_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Stoican",
        description: "Declaratie avere Stoican",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-uat/Stoican_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Stan",
        description: "Declaratie avere Stan",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-uat/Stan_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Serban",
        description: "Declaratie avere Serban",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-uat/Serban_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Sandu",
        description: "Declaratie avere Sandu",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-uat/Sandu_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Paun",
        description: "Declaratie avere Paun",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-uat/Paun_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Pandele",
        description: "Declaratie avere Pandele",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-uat/Pandele_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Lita",
        description: "Declaratie avere Lita",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-uat/Lita_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Linca",
        description: "Declaratie avere Linca",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-uat/Linca_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Dumitru",
        description: "Declaratie avere Dumitru",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-uat/Dumitru_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Balan",
        description: "Declaratie avere Balan",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-uat/Balan_Avere_redaction.pdf"],
        },
        {
        title: "Declaratie avere 3_Stefan",
        description: "Declaratie avere 3_Stefan",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-uat/3_Stefan_Avere_redaction.pdf"],
        },
        {
        title: "Declaratie avere 2_Viceprimar",
        description: "Declaratie avere 2_Viceprimar",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-uat/2_Viceprimar_Avere_redaction.pdf"],
        },
        {
        title: "Declaratie avere 1_Primar",
        description: "Declaratie avere 1_Primar",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-uat/1_Primar_Avere_redaction.pdf"],
        },
  ];

  const years = anunturi
    .map((anunt) => anunt.date.split(".")[2])
    .filter((year, index, self) => self.indexOf(year) === index);

  return (
    
    <div className="container-aux">
      <div className="helper">
        <ComponentaHeroSection />
      </div>
      <DepartLine text="Declaratii de avere"/>
      <div className="container-2">
      <Row>
        <Col xs={9} md={12}>
          {anunturi
            .filter(
              (anunt) =>
                selectedYear === "" || anunt.date.split(".")[2] === selectedYear
            )
            .map((anunt, index) => (
              <AnuntItem
                key={index}
                title={anunt.title}
                description={anunt.description}
                date={anunt.date}
                text={anunt.text}
                pdfs={anunt.pdfs} 
              />
            ))}
        </Col>
      </Row>
      </div>
    </div>
  );
};

export default Hotarari;
