import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Anunturi from './components/InformatiiPublice/Anunturi';
import Hotarari from './components/InformatiiPublice/Hotarari';
import Home from './components/Home';
import MonitorOficialLocal from './components/MonitorulOficialLocal/MonitorOficialLocal';
import Footer from './components/Footer';
import Primar from './components/Administratie/Primar';
import VicePrimar from './components/Administratie/Viceprimar';
import AdministratorPublic from './components/Administratie/AdministratorulPublic';
import Sna from './components/Administratie/Sna';
import CodEtica from './components/Administratie/CodEtica';
import Po from './components/Administratie/po';
import Secretar from './components/Administratie/SecretarulGeneral';
import Despre from './components/Comunitate/Despre';
import Evenimente from './components/Comunitate/Evenimente';
import Rof from './components/Organizare/rof';
import Departamente from './components/Organizare/Departamente';
import DecAvereConsiliu from './components/ConsiliuLocal/DeclaratiiAvereConsiliu';
import DecIntereseConsiliu from './components/ConsiliuLocal/DeclaratiiIntereseConsiliu';
import DecAvereUAT from './components/InformatiiPublice/DeclaratiiAvereUAT';
import DecIntereseUAT from './components/InformatiiPublice/DeclaratiiIntereseUAT';
import ProtectiaDatelor from './components/InformatiiPublice/ProtectiaDatelor';
import IstoricComuna from './components/Comunitate/Istoric';
import NavbarMobile from './components/NavbarMobile';
import SwipeableComponent from './components/SwipableComponent';
import Votare from './components/Votare2024/Votare2024';


function App() {
  return (
    <>
    <Router>
      <SwipeableComponent>
        <Navbar sticky="top"/>
        <NavbarMobile className="mobile-navbar" />
          <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/informatii-publice/anunturi" element={<Anunturi />} />
          <Route path="/informatii-publice/hotarari" element={<Hotarari />} />
          <Route path="/informatii-publice/declaratii-de-avere" element={<DecAvereUAT />} />
          <Route path="/informatii-publice/declaratii-de-interese" element={<DecIntereseUAT />} />
          <Route path="/informatii-publice/protejarea-datelor-cu-caracter-personal" element={<ProtectiaDatelor />} />
          <Route path="/alegeri2024/alegeri" element={<Votare />} />
          <Route path="/comunitate/despre" element={<Despre />} />
          <Route path="/comunitate/istoric" element={<IstoricComuna />} />
          <Route path="/comunitate/evenimente" element={<Evenimente />} />
          <Route path="/administratie/primar" element={<Primar />} />
          <Route path="/administratie/viceprimar" element={<VicePrimar />} />
          <Route path="/administratie/administratorul-public" element={<AdministratorPublic />} />
          <Route path="/administratie/secretarul-general" element={<Secretar />} />
          <Route path="/administratie/sna" element={<Sna />} />
          <Route path="/administratie/cod-etica" element={<CodEtica />} />
          <Route path="/administratie/po" element={<Po />} />
          <Route path="/departamente/rof" element={<Rof />} />
          <Route path="/departamente/departamente" element={<Departamente />} />
          <Route path="/monitorul-oficial-local" element={<MonitorOficialLocal />} />
          <Route path="/consiliu-local/consiliu-declaratii-interese" element={<DecIntereseConsiliu />} />
          <Route path="/consiliu-local/consiliu-declaratii-avere" element={<DecAvereConsiliu />} />
          </Routes>
      </SwipeableComponent>
      <Footer />
    </Router>
    </>
  );
}

export default App;
