import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const data = [
  { name: 'Dragodana', pop: 1300 },
  { name: 'Boboci', pop: 600 },
  { name: 'Burduca', pop: 800 },
  { name: 'Cuparu', pop: 400 },
  { name: 'Padureni', pop: 300 },
  { name: 'PdM', pop: 2900 },
  { name: 'Straosti', pop: 350 },
];

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', padding: '6px', borderRadius: '4px' }}>
          <p className="label" style={{ color: 'white' }}>{`Populație: ${payload[0].value}`}</p>
        </div>
      );
    }
  
    return null;
  };

const CustomBarChart = () => (
  <BarChart
    width={800}
    height={400}
    data={data}
    margin={{
      top: 5,
      right: 30,
      left: 20,
      bottom: 5,
    }}
  >
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="name" />
    <YAxis />
    <Tooltip content={<CustomTooltip />} />
    <Legend
        layout="vertical"
        verticalAlign="middle"
        align="right"
        payload={[
            {
            type: 'square',
            color: '#ff0000',
            value: 'Populație',
            },
        ]}
        formatter={() => 'Populație'}
        wrapperStyle={{ paddingLeft: '20px' }}
        />
    <Bar dataKey="pop" fill="#ff0000" />
  </BarChart>
);

export default CustomBarChart;
