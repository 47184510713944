import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import AnuntItem from "../InformatiiPublice/ComponenteInformatiiPublice/ComponentaLista";
import ComponentaHeroSection from "../InformatiiPublice/ComponenteInformatiiPublice/ComponentaHeroSection";
import DepartLine from "../DepartLine";
import "../InformatiiPublice/Anunturi.css";

const Hotarari = () => {
  const [selectedYear, setSelectedYear] = useState("");
  const anunturi = [
    {
        title: "Declaratie avere Chivulescu",
        description: "Declaratie avere Chivulescu",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-consiliu/Chivulescu_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere David",
        description: "Declaratie avere David",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-consiliu/David_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Gerogescu",
        description: "Declaratie avere Gerogescu",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-consiliu/Gerogescu_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Ionete",
        description: "Declaratie avere Ionete",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-consiliu/Ionete_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Ionita",
        description: "Declaratie avere Ionita",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-consiliu/Ionita_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Nastase",
        description: "Declaratie avere Nastase",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-consiliu/Nastase_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Nedelcu",
        description: "Declaratie avere Nedelcu",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-consiliu/Nedelcu_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Nicolae",
        description: "Declaratie avere Nicolae",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-consiliu/Nicolae_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Niculae",
        description: "Declaratie avere Niculae",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-consiliu/Niculae_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Popa",
        description: "Declaratie avere Popa",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-consiliu/Popa_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Radu",
        description: "Declaratie avere Radu",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-consiliu/Radu_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Stan",
        description: "Declaratie avere Stan",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-consiliu/Stan_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Tudor",
        description: "Declaratie avere Tudor",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-consiliu/Tudor_Avere_redaction.pdf"],
      },
      {
        title: "Declaratie avere Voinescu",
        description: "Declaratie avere Voinescu",
        date: "16.04.2020",
        pdfs: ["declaratii-avere-consiliu/Voinescu_Avere_redaction.pdf"],
      },
  ];

  const years = anunturi
    .map((anunt) => anunt.date.split(".")[2])
    .filter((year, index, self) => self.indexOf(year) === index);

  return (
    
    <div className="container-aux">
      <div className="helper">
        <ComponentaHeroSection />
      </div>
      <DepartLine text="Declaratii de avere"/>
      <div className="container-2">
      <Row>
        <Col xs={9} md={12}>
          {anunturi
            .filter(
              (anunt) =>
                selectedYear === "" || anunt.date.split(".")[2] === selectedYear
            )
            .map((anunt, index) => (
              <AnuntItem
                key={index}
                title={anunt.title}
                description={anunt.description}
                date={anunt.date}
                text={anunt.text}
                pdfs={anunt.pdfs} 
              />
            ))}
        </Col>
      </Row>
      </div>
    </div>
  );
};

export default Hotarari;
