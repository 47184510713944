import React from 'react';
import './DepartLine.css';

const DepartLine = ({text}) => {
  return (
    <div className="depart-line">
      <div className="line">
        <p className="text">{text}</p>
      </div>
    </div>
  );
};

export default DepartLine;

