import React from 'react';
import './ComponentaPrezentareOm.css';
import imagineProfil from '../../../images/pngwing.png';

function ComponentaPRezentareSecretar() {
  return (
    <div className="container-om">
      <div className="imagine">
        <img src={imagineProfil} alt="Profil" />
      </div>
      <div className="text center-content">
        <h2  className='nume'>Marian Stoica</h2>
        <p className="atributii2">Conform Legii 215/2001 republicată, secretarul are următoarele atribuții principale:</p>
        <ul className= "lista-atributii">
            <li>Avizează pentru legalitate dispozițiile primarului și ale președintelui consiliului județean, hotărârile consiliului local și consiliului județean;</li>
            <li>Participă la ședințele consiliului local și consiliului județean;</li>
            <li>Asigură gestionarea procedurilor administrative privind relația dintre consiliul local și primar, consiliul județean și președintele acestuia, și între aceștia și prefect;</li>
            <li>Organizează arhiva și evidența statistică a hotărârilor consiliului local și a dispozițiilor primarului, respectiv a hotărârilor consiliului județean și a dispozițiilor președintelui consiliului județean;</li>
            <li>Asigură transparența și comunicarea actelor prevăzute la lit. a), în condițiile Legii nr. 544/2001 privind liberul acces la informațiile de interes public;</li>
            <li>Asigură procedurile de convocare a consiliului local, respectiv a consiliului județean, efectuarea lucrărilor de secretariat, comunicarea ordinii de zi, întocmirea procesului-verbal al ședințelor consiliului local și consiliului județean, și redactarea hotărârilor;</li>
            <li>Pregătește lucrările supuse dezbaterii consiliului local, respectiv a consiliului județean și comisiilor de specialitate ale acestuia;</li>
            <li>Alte atribuții prevăzute de lege sau însărcinări date de consiliul local, de primar, de consiliul județean sau de președintele consiliului județean, după caz.</li>
        </ul>
        </div>
    </div>
  );
}

export default ComponentaPRezentareSecretar;
